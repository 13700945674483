import React from 'react';
import './info-columns.scss'


const InfoColumns = ({ props }) => {
    return (
        <div className="columns-container">

            {
                props.title?.split('#').length > 1 ?
                    <div className="title-container">
                        <p className="subtitle-content">{props.title.split('#')[0]}</p>
                        <p className="subtitle-content">{props.title.split('#')[1]}</p>
                        {props.title.split('#')[2] ? <p>{props.title.split('#')[2]}</p> : ""}
                    </div>
                    : <h2>{props.title}</h2>
            }

            <div>
                <div className="columns-content uk-child-width-1-2@s uk-child-width-1-1 uk-grid-small uk-text-center uk-grid" data-uk-grid>

                    <div style={{ paddingRight: "15px" }}>
                        {props.leftSubtitle ? <p className="left-subtitle">{props.leftSubtitle}</p> : ""}

                        {
                            props.leftColumn.map((item, index) => {
                                return (
                                    <div key={index} className="left-column">
                                        {/* <img src={item.image} alt="column-image" className="column-image" /> */}
                                        <p>{item.reason}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div>
                        {props.rightSubtitle ? <p className="right-subtitle">{props.rightSubtitle}</p> : ""}

                        {
                            props.rightColumn.map((item, index) => {
                                return (
                                    <div key={index} className="right-column">
                                        {/* <img src={item.image} alt="column-image" className="column-image" /> */}
                                        <p>{item.reason}</p>
                                    </div>
                                )
                            })
                        }
                    </div>


                </div>
            </div>

            {
                props.ctaURL ?
                    <div className="footer-content">
                        <a href={props.ctaURL} className="button">{props.ctaTitle}</a>
                    </div>
                    : ""
            }
        </div>

    )
}

export default InfoColumns