import React from "react"
import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'
import Layout from "../layouts"

import { headerULA, sliderCompaniesULA, infoColumnsULA, cardsULA, paragraphContent, banner } from "../utils/homepageInformation";

import Header from "../components/header/header";
import Comparison from "../components/comparison/comparison";
import Banner from "../components/banner/banner";
import ImageCarousel from "../components/image-carousel/image-carousel";
import InfoColumns from "../components/info-columns/info-columns";
import InfoImage from "../components/info-image/info-image";


const IndexPage = () => {


  return (
    <Layout
      SEOTitle={"Legal Expenses Insurance Providers - United Legal Assistance"}
      SEODescription={"We are specialist providers of Legal Expenses Insurance. We will provide a hassle free service to reduce the risk of high legal fees if something goes wrong"}
      footerDisclaimer={""}>

      <Header props={headerULA} />

      <ImageCarousel props={sliderCompaniesULA} />

      <InfoColumns props={infoColumnsULA} />

      <Comparison props={cardsULA} />

      <InfoImage props={paragraphContent} origin="homepage" />

      <Banner props={banner} />

    </Layout>
  )

}

export default IndexPage