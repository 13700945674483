//ula
import webpHeader from '../assets/homepageHeader.webp'
import pngHeader from '../assets/homepageHeaderPng.png'
import logoULA from '../assets/logoHeader.webp'
import platform from '../assets/ulaPlatform.webp'

//companies slider
import image1 from '../assets/1-dcj.webp'
import image2 from '../assets/2-wrightsure.webp'
import image3 from '../assets/3-jbs.webp'
import image4 from '../assets/4-fnc.webp'
import image5 from '../assets/5-russell.webp'
import image6 from '../assets/6-whittlesey.webp'
import image7 from '../assets/7-htc.webp'
import image8 from '../assets/8-specialist.webp'
import image9 from '../assets/9-sib.webp'
import image10 from '../assets/10-trdirect.webp'
import image11 from '../assets/11-jabrokers.webp'
import image12 from '../assets/12-bislogo.webp'
import image13 from '../assets/13-hinks.webp'
import image14 from '../assets/14-west-craven.webp'
import image15 from '../assets/15-wcib.webp'
import image16 from '../assets/16-business-compare.webp'


export const headerULA = {
  image: webpHeader,
  pngImage: pngHeader,
  Icon: logoULA,
  Subtitle: "We are specialist providers of Legal Expenses Insurance, meaning if you have an accident or if you want to claim, we will provide a hassle free service to reduce the risk of high legal fees if something goes wrong. # With over 25 years experience in the insurance industry, we provide the following services to Insurance Brokers in the United Kingdom.",
  FirstCTATitle: "OUR SERVICES",
  FirstCTAURL: "/services",
  SecondCTATitle: "ABOUT US",
  SecondCTAURL: "/about",
}

export const sliderCompaniesULA = {
  theme: 'light',
  title: 'TRUSTED BY HUNDREDS',
  description: '',
  images: [
    {
      image: image1,
    },
    {
      image: image2,
    },
    {
      image: image3,
    },
    {
      image: image4,
    },
    {
      image: image5,
    },
    {
      image: image6,
    },
    {
      image: image7,
    },
    {
      image: image8,
    },
    {
      image: image9,
    },
    {
      image: image10,
    },
    {
      image: image11,
    },
    {
      image: image12,
    },
    {
      image: image13,
    },
    {
      image: image14,
    },
    {
      image: image15,
    },
    {
      image: image16,
    },
  ]
}

export const infoColumnsULA = {
  title: 'Together We Make # The Difference',
  leftSubtitle: 'ULA TECH',
  rightSubtitle: 'PERSONABLE TEAM',
  leftColumn: [
    {
      reason: 'Optimise automation and digitisation through a highly configurable back-office system.',
    },
  ],
  rightColumn: [
    {
      reason: 'Unlike our larger, corporate competitors, our brokers with the same names and have direct access to our Directors.',
    },
  ],
  ctaTitle: 'FIND MORE',
  ctaURL: '/about',

}

export const cardsULA = {
  title: "",
  comparison: [
    {
      theme: "dark",
      bgColor: "#015D8C",
      icon: "users",
      subtitle: "PERSONABLE TEAM",
      info: "Honest and professional team with over 25 years experience in the insurance and claims management industry",
      label: "ABOUT US",
      url: "/about"
    },
    {
      theme: "light",
      bgColor: "#127F91",
      icon: "desktop",
      subtitle: "ULA TECH",
      info: "Flexible claims management solutions tailored to individual requirements",
      label: "SERVICES",
      url: "/services"
    },
    {
      theme: "dark",
      bgColor: "#015D8C",
      icon: "star",
      subtitle: "TAILORMADE PRODUCTS",
      info: "Bespoke service to manage your company’s needs",
      label: "PRODUCTS",
      url: "/products"
    },

  ]
}

export const paragraphContent = {
  "homepage": [
    {
      subtitle: 'SUCCESS. NOTHING ELSE.',
      title: 'One Platform For All Your Customers Needs',
      alignment: 'center',
      inline: true,
      description: [
        {
          text: "We offer a selection of insurance products to meet your needs. Each insurance product has different levels of cover, and we are happy to discuss in detail which level will best suit you."
        },
        {
          text: "- Flexible claims management solutions tailored to individual requirements",
        },
        {
          text: "- Bespoke service to manage your company's fleet, minimising unnecessary risk"
        },
        {
          text: "- 24-hour claims reporting line, where we support clients through a stressful process."
        },
      ],
      url: "/services",
      label: "FIND MORE",
      image: platform,
    }
  ]
}

export const banner = {
  small: true,
  description: "We may receive a recommendation fee if we process your Personal Injury case and select one of our panel Solicitors.",
}

